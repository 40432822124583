import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMyLink, LazyProductLink, LazyNuxtRating, LazyNuxtStar, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["MyLink", LazyMyLink],
["ProductLink", LazyProductLink],
["NuxtRating", LazyNuxtRating],
["NuxtStar", LazyNuxtStar],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
